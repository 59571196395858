import { ReactComponent as FlerbostadshusIcon } from "../images/FlerbostadshusIcon.svg";
import { ReactComponent as RadhusIcon } from "../images/RadhusIcon.svg";
import { ReactComponent as VillorIcon } from "../images/VillorIcon.svg";
import { ReactComponent as VardboendeIcon } from "../images/VardboendeIcon.svg";
import { ReactComponent as StudentlagenheterIcon } from "../images/StudentlagenheterIcon.svg";
import { ReactComponent as HotellIcon } from "../images/HotelIcon.svg";
import { ReactComponent as WorkIcon } from "../images/OfficeIcon.svg";
import { ReactComponent as ShopIcon } from "../images/TradeIcon.svg";
import { ReactComponent as VarmlagerIcon } from "../images/VarmlagerIcon.svg";
import { ReactComponent as KalllagerIcon } from "../images/KallLagerIcon.svg";
import { ReactComponent as KylLagerIcon } from "../images/KylLagerIcon.svg";
import { ReactComponent as IndustribyggnadIcon } from "../images/IndustribyggnadIcon.svg";
import { ReactComponent as SchoolIcon } from "../images/SchoolIcon.svg";
import { ReactComponent as ForskolaIcon } from "../images/ForskolaIcon.svg";
import { ReactComponent as ParkeringshusIcon } from "../images/ParkeringshusIcon.svg";
import { ReactComponent as IdrottshallIcon } from "../images/IdrottshallIcon.svg";
import { ReactComponent as OmbyIcon1 } from "../images/OmbyIcon1.svg";
import { ReactComponent as PipeIcon } from "../images/PipeIcon.svg";
import { ReactComponent as TrunkIcon } from "../images/TrunkIcon.svg";
import { ReactComponent as ValveIcon } from "../images/ValveIcon.svg";
import { ReactComponent as PlanIcon } from "../images/PlanIcon.svg";
import { ReactComponent as NewIcon } from "../images/NewIcon.svg";
import { ReactComponent as SketchIcon } from "../images/SketchIcon.svg";
import { ReactComponent as DesignIcon } from "../images/DesignIcon.svg";
import { ReactComponent as ToolboxIcon } from "../images/ToolboxIcon.svg";
import { ReactComponent as InteriorIcon } from "../images/InteriorIcon.svg";
import { ReactComponent as HelmetIcon } from "../images/HelmetIcon.svg";
import { ReactComponent as DocumentIcon } from "../images/DocumentIcon.svg";
import { ReactComponent as School2Icon } from "../images/School2Icon.svg";

const icons = {
    1: <FlerbostadshusIcon/>,
    2: <RadhusIcon/>,
    3: <VillorIcon/>,
    4: <VardboendeIcon/>,
    5: <StudentlagenheterIcon/>,
    6: <HotellIcon/>,
    7: <WorkIcon/>,
    8: <ShopIcon/>,
    9: <VarmlagerIcon/>,
    10: <KalllagerIcon/>,
    11: <KylLagerIcon/>,
    12: <IndustribyggnadIcon/>,
    13: <SchoolIcon/>,
    14: <ForskolaIcon/>,
    15: <ParkeringshusIcon/>,
    16: <IdrottshallIcon/>,
    17: <OmbyIcon1/>,
    18: <PipeIcon />,
    19: <TrunkIcon />,
    20: <ValveIcon />,
    21: <PlanIcon />,
    22: <NewIcon />,
    23: <SketchIcon />,
    24: <DesignIcon />,
    25: <ToolboxIcon />,
    26: <InteriorIcon />,
    27: <HelmetIcon />,
    28: <DocumentIcon />,
    29: <SchoolIcon />,
    30: <School2Icon />
};

export { icons };