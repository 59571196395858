export function SchoolIcon(props) {
  return (
    <svg
      {...props}
      width={props.size || 31}
      height={props.size || 31}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.4833 12.4H21.7V10.85C21.7 10.6847 21.6209 10.5293 21.4871 10.432L16.0167 6.45368V4.65H21.7C21.9853 4.65 22.2167 4.41866 22.2167 4.13333V1.03333C22.2167 0.748004 21.9853 0.516667 21.7 0.516667H16.0167C16.0167 0.231337 15.7853 0 15.5 0C15.2147 0 14.9833 0.231337 14.9833 0.516667V6.45368L9.51287 10.432C9.37911 10.5293 9.3 10.6847 9.3 10.85V12.4H0.516667C0.231337 12.4 0 12.6313 0 12.9167V30.4833C0 30.7687 0.231337 31 0.516667 31H30.4833C30.7687 31 31 30.7687 31 30.4833V12.9167C31 12.6313 30.7687 12.4 30.4833 12.4ZM16.0167 1.55H21.1833V3.61667H16.0167V1.55ZM9.3 29.9667H1.03333V13.4333H9.3V29.9667ZM17.5667 29.9667H13.4333V24.8H17.5667V29.9667ZM20.6667 29.9667H18.6V24.2833C18.6 23.998 18.3687 23.7667 18.0833 23.7667H12.9167C12.6313 23.7667 12.4 23.998 12.4 24.2833V29.9667H10.3333V11.113L15.5 7.35527L20.6667 11.113V29.9667ZM29.9667 29.9667H21.7V13.4333H29.9667V29.9667Z"
        fill={props.color}
      />
      <path
        d="M4.65065 14.9834H2.58398V16.0167H4.65065V14.9834Z"
        fill={props.color}
      />
      <path
        d="M7.75026 14.9834H5.68359V16.0167H7.75026V14.9834Z"
        fill={props.color}
      />
      <path
        d="M4.65065 17.0498H2.58398V18.0831H4.65065V17.0498Z"
        fill={props.color}
      />
      <path
        d="M7.75026 17.0498H5.68359V18.0831H7.75026V17.0498Z"
        fill={props.color}
      />
      <path
        d="M4.65065 19.1167H2.58398V20.15H4.65065V19.1167Z"
        fill={props.color}
      />
      <path
        d="M7.75026 19.1167H5.68359V20.15H7.75026V19.1167Z"
        fill={props.color}
      />
      <path
        d="M4.65065 21.1831H2.58398V22.2164H4.65065V21.1831Z"
        fill={props.color}
      />
      <path
        d="M7.75026 21.1831H5.68359V22.2164H7.75026V21.1831Z"
        fill={props.color}
      />
      <path
        d="M4.65065 23.25H2.58398V24.2833H4.65065V23.25Z"
        fill={props.color}
      />
      <path
        d="M7.75026 23.25H5.68359V24.2833H7.75026V23.25Z"
        fill={props.color}
      />
      <path
        d="M25.3167 14.9834H23.25V16.0167H25.3167V14.9834Z"
        fill={props.color}
      />
      <path
        d="M28.4163 14.9834H26.3496V16.0167H28.4163V14.9834Z"
        fill={props.color}
      />
      <path
        d="M25.3167 17.0498H23.25V18.0831H25.3167V17.0498Z"
        fill={props.color}
      />
      <path
        d="M28.4163 17.0498H26.3496V18.0831H28.4163V17.0498Z"
        fill={props.color}
      />
      <path
        d="M25.3167 19.1167H23.25V20.15H25.3167V19.1167Z"
        fill={props.color}
      />
      <path
        d="M28.4163 19.1167H26.3496V20.15H28.4163V19.1167Z"
        fill={props.color}
      />
      <path
        d="M25.3167 21.1831H23.25V22.2164H25.3167V21.1831Z"
        fill={props.color}
      />
      <path
        d="M28.4163 21.1831H26.3496V22.2164H28.4163V21.1831Z"
        fill={props.color}
      />
      <path
        d="M25.3167 23.25H23.25V24.2833H25.3167V23.25Z"
        fill={props.color}
      />
      <path
        d="M28.4163 23.25H26.3496V24.2833H28.4163V23.25Z"
        fill={props.color}
      />
      <path
        d="M11.8828 15.5C11.8851 17.4964 13.503 19.1144 15.4995 19.1166C17.4969 19.1166 19.1161 17.4974 19.1161 15.5C19.1161 13.5025 17.4969 11.8833 15.4995 11.8833C13.502 11.8833 11.8828 13.5025 11.8828 15.5ZM18.0828 15.5C18.0828 16.9267 16.9262 18.0833 15.4995 18.0833C14.0728 18.0833 12.9161 16.9267 12.9161 15.5C12.9178 14.074 14.0735 12.9183 15.4995 12.9166C16.9262 12.9166 18.0828 14.0733 18.0828 15.5Z"
        fill={props.color}
      />
      <path
        d="M14.9826 14.9831H13.9492V16.0164H16.0159V13.4331H14.9826V14.9831Z"
        fill={props.color}
      />
    </svg>
  );
}
